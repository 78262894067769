import GrTranslationOverrider from "../translations/GrTranslationOverrider"

export class TranslationOverrider {
  constructor() {
    // Add language translations
    const projectI18nTranslations = {
      el: new GrTranslationOverrider()
    }

    // Assign project translations to a window variable
    window.projectI18nTranslations = projectI18nTranslations
  }
}