export default class GrTranslationOverrider {
  constructor() {
    return {
      shared: {
        header: {
          search:{
            text_search: "Αναζήτησε αυτό που χρειάζεσαι"
          }
        }
      }
    }
  }
}